<template>
  <b-card
    no-body
    class="card-revenue-budget"
  >
    <b-card-header>
      <b-card-title>Compliance Overview</b-card-title>
    </b-card-header>
    <b-row class="mx-0">
      <b-col
        md="8"
        class="revenue-report-wrapper pt-0"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-success svg-font-small-3 mr-50 cursor-pointer" />
              <span>Passed</span>
            </div>
            <div class="d-flex align-items-center">
              <span class="bullet bullet-danger svg-font-small-3 mr-50 cursor-pointer" />
              <span>Fail</span>
            </div>
          </div>
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          type="bar"
          height="230"
          :options="chartData.chartOptions"
          :series="chartData.series"
        />
      </b-col>

      <b-col
        md="4"
        class="budget-wrapper pt-0"
      >
        <b-avatar
          class="mb-1"
          variant="light-warning"
          size="45"
        >
          <feather-icon
            size="28"
            icon="CloudLightningIcon"
          />
        </b-avatar>
        <h2 class="mt-1 mb-25">
          25 Open
        </h2>
        <div class="d-flex justify-content-center">
          <span>56 Fixed</span>
        </div>
        <vue-apex-charts
          id="budget-chart"
          type="line"
          height="80"
          :options="budgetChart.options"
          :series="budgetChart.series"
        />
        <router-link
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="btn btn-primary"
          to="/scenarios"
        >
          Fix Issues
        </router-link>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BAvatar, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // budget chart
      budgetChart: {
        series: [
          {
            data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
          },
          {
            data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
          },
        ],
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            sparkline: { enabled: true },
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 5],
            width: [2],
          },
          colors: [$themeColors.primary, '#dcdae3'],
          tooltip: {
            enabled: false,
          },
        },
      },
    }
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
}
</script>
